<template lang='pug'>
.error
  .error-con
    row
      .title
        icon(type='ios-sad')
        | 404
      .content {{$t("Page not found.")}}
</template>

<script>
export default {
  name: 'error404',
  components: {
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>
<style lang='sass' scoped>
  html, body, #app
    height: 100%
    width: 100%

  .error
    width: 100%
    height: 100%
    // background-image: url('../../assets/images/login-bg.jpg')
    background-size: cover
    background-position: center
    // background-color: #5cadff
    background-color: #515a6e
    position: relative

    &-con
      color: #fff
      position: absolute
      left: 50%
      top: 50%
      margin-left: -150px
      transform: translateY(-60%)
      width: 300px

      .title
        text-align: right
        font-size: 90px
      .content
        text-align: right
        font-size: 16px
        margin-top: -20px

</style>
